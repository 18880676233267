import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/plugins'
import router from './router'
import Keycloak from 'keycloak-js'
import api from './modules/api'

Vue.config.productionTip = false

// api.backendUrl = "http://localhost:8090"
 api.backendUrl = "https://supt.nisse.ru"

let initOptions = {
    url: 'https://auth.nisse.ru',
    realm: 'sup',
    clientId: 'frontend',
    onLoad: 'login-required',
}
let keycloak = Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak

Vue.filter('df', function (value) {
    return value != null ? value : '—';
})


Vue.filter('formatPrice', function (value) {
  if (value != null) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₽";
  } else return('—')
})

function formatDateTime(value, options) {
    return value ? (new Date(value)).toLocaleDateString('ru-RU', options) : '—'
}

Vue.filter('formatDate', function (value) {
    return formatDateTime(value, {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'})
})
Vue.filter('formatDateTime', function (value) {
  return formatDateTime(value, {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'})
})

keycloak.init({onLoad: initOptions.onLoad, checkLoginIframe: false}).then(async (auth) => {
    if (!auth) {
        window.location.reload()
    } else {
        console.log(keycloak.idTokenParsed)
        Vue.prototype.$user = {
            id: keycloak.idTokenParsed.userId,
            userName: keycloak.idTokenParsed.username,
            roles: keycloak.idTokenParsed.roles
        }

        /* eslint-disable no-new */
        new Vue({
            el: '#app',
            render: h => h(App, {props: {keycloak: keycloak}}),
            vuetify,
            router
        })
    }

    // Token Refresh
    setInterval(() => {
        keycloak.updateToken(70).then((refreshed) => {
            if (!refreshed) {
                // console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
            }
        }).catch(() => {
            console.log('Failed to refresh token')
        })
    }, 6000)
}).catch((e) => {
    console.log('Authentication Failed: ' + e)
})

