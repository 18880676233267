<template>
  <v-app id="app">


	<header class="header">

		<a href="/">Система Управления Проектами</a>


		<nav>

        <router-link to="/" :class="isActiveSection('DESKTOP') ? 'router-link-exact-active active' : ''">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M22.4 22.4001H13.1555V15.4668H22.4V22.4001ZM10.8444 22.4001H1.59998V10.8445H10.8444V22.4001ZM22.4 13.1557H13.1555V1.6001H22.4V13.1557ZM10.8444 8.53343H1.59998V1.6001H10.8444V8.53343Z" fill="#AEC9E5"/>
					</svg>
					Рабочий стол
				</router-link>

				<router-link to="/tenders/" :class="isActiveSection('TENDERS') ? 'router-link-exact-active active' : ''" v-if="userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN')">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H24V24H0V0Z" fill="white"/>
						</mask>
						<g mask="url(#mask0)">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M1.59995 20.8001H13.5999V22.4001H1.59995V20.8001ZM5.74884 8.47735L8.52566 5.72745L22.3999 19.4838L19.6251 22.2346L5.74884 8.47735ZM12.688 1.6001L18.2387 7.10184L15.4618 9.85466L9.91409 4.34903L12.688 1.6001ZM4.35554 9.85371L9.90623 15.3564L7.13138 18.1073L1.58069 12.6046L4.35554 9.85371Z" fill="#AEC9E5"/>
						</g>
					</svg>
					Тендеры
				</router-link>

				<router-link to="/applications/" :class="isActiveSection('APPLICATIONS') ? 'router-link-exact-active active' : ''">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M12 24L2.39999 16.5466L4.12799 15.205L11.9893 21.3061L19.8613 15.1954L21.6 16.5466L12 24ZM12 19.4534L2.39999 12L4.12799 10.6584L11.9893 16.7595L19.8613 10.6477L21.6 12L12 19.4534ZM12 14.9068L4.13866 8.80568L2.39999 7.45342L12 0L21.6 7.45342L19.8507 8.80568L12 14.9068Z" fill="#AEC9E5"/>
					</svg>
					Заявки
				</router-link>

				<router-link to="/contracts/" :class="isActiveSection('CONTRACTS') ? 'router-link-exact-active active' : ''" v-if="userInOneOfRoles('ROLE_FORMAL', 'ROLE_PARTNER', 'ROLE_ADMIN')">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18 22.4001H6C4.89543 22.4001 4 21.4689 4 20.3202V3.68091C4 2.53221 4.89543 1.601 6 1.601H13C13.009 1.5998 13.018 1.5998 13.027 1.601H13.033C13.0424 1.60408 13.0522 1.60617 13.062 1.60724C13.1502 1.61312 13.2373 1.63095 13.321 1.66028H13.336H13.351H13.363C13.3815 1.67372 13.3988 1.68868 13.415 1.705C13.5239 1.75536 13.6232 1.82583 13.708 1.91299L19.708 8.15272C19.7918 8.24089 19.8596 8.34413 19.908 8.45743C19.917 8.4803 19.924 8.50214 19.931 8.52606L19.941 8.55518C19.9689 8.6419 19.9854 8.73216 19.99 8.82349C19.9909 8.83385 19.9932 8.84402 19.997 8.85365V8.85989C19.9986 8.8667 19.9996 8.87367 20 8.88069V20.3202C20 20.8718 19.7893 21.4008 19.4142 21.7909C19.0391 22.181 18.5304 22.4001 18 22.4001ZM13 3.68091V8.88069H18L13 3.68091Z" fill="#AEC9E5"/>
					</svg>
					Договоры
				</router-link>

				<router-link to="/projects/" :class="$route.meta.section === 'PROJECTS' ? 'router-link-exact-active active' : ''" v-if="userInOneOfRoles('ROLE_PARTNER', 'ROLE_ADMIN')">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M13.9477 2.57892C13.8362 2.00916 13.3555 1.6001 12.7979 1.6001H4.37333C3.72532 1.6001 3.2 2.14789 3.2 2.82363V21.1766C3.2 21.8523 3.72532 22.4001 4.37333 22.4001C5.02134 22.4001 5.54666 21.8523 5.54666 21.1766V13.8354H12.1173L12.3989 15.3036C12.5054 15.8766 12.9892 16.2884 13.5488 16.2824H19.6267C20.2747 16.2824 20.8 15.7347 20.8 15.0589V5.27069C20.8 4.59495 20.2747 4.04716 19.6267 4.04716H14.2293L13.9477 2.57892Z" fill="#AEC9E5"/>
					</svg>
					Проекты
				</router-link>

        <router-link to="/employees/" :class="isActiveSection('EMPLOYEES') ? 'router-link-exact-active active' : ''">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M20.6333 4H3.36672C1.95083 4 0.799999 5.09603 0.799999 6.4445V17.5555C0.799999 18.904 1.95083 20 3.36672 20H20.6333C22.0492 20 23.2 18.904 23.2 17.5555V6.4445C23.2 5.09603 22.0492 4 20.6333 4V4ZM7.8 7.5555C9.08618 7.5555 10.1333 8.5529 10.1333 9.77783C10.1333 11.0026 9.08618 12 7.8 12C6.51382 12 5.46672 11.0026 5.46672 9.77783C5.46672 8.5529 6.51382 7.5555 7.8 7.5555ZM12 15.7778C12 16.1458 11.6864 16.4445 11.3 16.4445H4.3C3.9136 16.4445 3.6 16.1458 3.6 15.7778V15.3333C3.6 13.9849 4.75083 12.8888 6.16672 12.8888H9.43328C10.8492 12.8888 12 13.9849 12 15.3333V15.7778ZM19.7 16.4445H14.5667C14.1803 16.4445 13.8667 16.1458 13.8667 15.7778C13.8667 15.4098 14.1803 15.1112 14.5667 15.1112H19.7C20.0864 15.1112 20.4 15.4098 20.4 15.7778C20.4 16.1458 20.0864 16.4445 19.7 16.4445ZM19.7 12.8888H14.5667C14.1803 12.8888 13.8667 12.5902 13.8667 12.2222C13.8667 11.8542 14.1803 11.5555 14.5667 11.5555H19.7C20.0864 11.5555 20.4 11.8542 20.4 12.2222C20.4 12.5902 20.0864 12.8888 19.7 12.8888ZM19.7 9.33333H14.5667C14.1803 9.33333 13.8667 9.03467 13.8667 8.66667C13.8667 8.29867 14.1803 8 14.5667 8H19.7C20.0864 8 20.4 8.29867 20.4 8.66667C20.4 9.03467 20.0864 9.33333 19.7 9.33333Z" fill="#AEC9E5"/>
					</svg>
					Сотрудники
        </router-link>


				<router-link to="/counterparties/" :class="isActiveSection('ORGS') ? 'router-link-exact-active active' : ''">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.9665 12.7656H18.2341C18.4107 13.2541 18.5072 13.7813 18.5072 14.3307V20.9464C18.5072 21.1754 18.4677 21.3953 18.3959 21.5998H21.2599C22.3297 21.5998 23.2 20.7204 23.2 19.6394V16.0329C23.2 14.2314 21.7495 12.7656 19.9665 12.7656Z" fill="#AEC9E5"/>
					<path d="M5.49285 14.3307C5.49285 13.7812 5.58932 13.2541 5.7659 12.7656H4.03357C2.25058 12.7656 0.800003 14.2314 0.800003 16.033V19.6395C0.800003 20.7204 1.67032 21.5999 2.74014 21.5999H5.60411C5.53232 21.3953 5.49285 21.1754 5.49285 20.9464V14.3307Z" fill="#AEC9E5"/>
					<path d="M13.9802 11.0635H10.0199C8.23686 11.0635 6.78629 12.5292 6.78629 14.3308V20.9465C6.78629 21.3073 7.07582 21.5999 7.433 21.5999H16.5671C16.9242 21.5999 17.2138 21.3074 17.2138 20.9465V14.3308C17.2138 12.5292 15.7632 11.0635 13.9802 11.0635Z" fill="#AEC9E5"/>
					<path d="M12 2.3999C9.85574 2.3999 8.11125 4.16262 8.11125 6.32936C8.11125 7.79903 8.91402 9.08259 10.0996 9.75648C10.6619 10.0761 11.3102 10.2588 12 10.2588C12.6899 10.2588 13.3382 10.0761 13.9005 9.75648C15.0861 9.08259 15.8888 7.79898 15.8888 6.32936C15.8888 4.16267 14.1443 2.3999 12 2.3999Z" fill="#AEC9E5"/>
					<path d="M5.17142 6.0625C3.56776 6.0625 2.26314 7.38076 2.26314 9.00118C2.26314 10.6216 3.56776 11.9399 5.17142 11.9399C5.57821 11.9399 5.96557 11.8547 6.31745 11.7017C6.92584 11.437 7.42748 10.9685 7.73789 10.3816C7.95576 9.96976 8.07971 9.49988 8.07971 9.00118C8.07971 7.38081 6.77508 6.0625 5.17142 6.0625Z" fill="#AEC9E5"/>
					<path d="M18.8286 6.0625C17.225 6.0625 15.9203 7.38076 15.9203 9.00118C15.9203 9.49993 16.0443 9.96981 16.2622 10.3816C16.5726 10.9685 17.0742 11.4371 17.6826 11.7017C18.0345 11.8547 18.4218 11.9399 18.8286 11.9399C20.4323 11.9399 21.7369 10.6216 21.7369 9.00118C21.7369 7.38076 20.4323 6.0625 18.8286 6.0625Z" fill="#AEC9E5"/>
					</svg>
					Контрагенты
				</router-link>
<!--				<router-link to="/analytics/" :class="$route.meta.section === 'ANALYTICS' ? 'router-link-exact-active active' : ''" v-if="userInOneOfRoles('ROLE_PARTNER', 'ROLE_ADMIN')">-->
<!--					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--					<path d="M12.0217 22.4001C6.46755 22.3953 1.89136 18.0406 1.6133 12.4955C1.33523 6.95041 5.45283 2.16009 10.9786 1.6001V5.81244C7.83971 6.34271 5.60059 9.14604 5.77815 12.3233C5.95571 15.5006 8.49324 18.0372 11.6717 18.2147C14.8502 18.3922 17.6546 16.1539 18.1851 13.0162H22.399C21.8564 18.3406 17.3746 22.3929 12.0206 22.4001H12.0217ZM22.4 10.9309H18.1861C17.7422 8.30929 15.6874 6.25563 13.0647 5.81244V1.6001C17.9953 2.10473 21.8936 6.00114 22.399 10.9298L22.4 10.9309Z" fill="#AEC9E5"/>-->
<!--					</svg>-->
<!--					Аналитика-->
<!--				</router-link>-->
				<router-link to="/knowledge/files" :class="$route.fullPath.startsWith('/knowledge') ? 'router-link-exact-active active' : ''">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M12 22.4001C6.25621 22.4001 1.59998 17.7439 1.59998 12.0001C1.59998 6.25634 6.25621 1.6001 12 1.6001C17.7437 1.6001 22.4 6.25634 22.4 12.0001C22.3937 17.7412 17.7411 22.3938 12 22.4001ZM9.90958 10.9497V13.0401H10.9496V17.2001H14.0904V15.1201H13.04L13.0504 10.9507L9.90958 10.9497ZM10.9496 6.8001V8.89986H13.0504V6.8001H10.9496Z" fill="#AEC9E5"/>
					</svg>
					База знаний
				</router-link>
				<router-link to="/entries/" :class="$route.meta.section === 'ENTRIES' ? 'router-link-exact-active active' : ''" v-if="userInOneOfRoles('ROLE_PARTNER', 'ROLE_ADMIN')">
					<svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM10 4.1L11.1 3L12.5 4.4L13.9 3L15 4.1L13.6 5.5L15 6.9L13.9 8L12.5 6.6L11.1 8L10 6.9L11.4 5.5L10 4.1ZM3.2 4.7H8.2V6.2H3.2V4.7ZM8.5 13H6.5V15H5V13H3V11.5H5V9.5H6.5V11.5H8.5V13ZM15 14.2H10V12.7H15V14.2ZM15 11.8H10V10.3H15V11.8Z" fill="#AEC9E5"/>
					</svg>
					Проводки
				</router-link>
				<router-link to="/entries1c/" :class="$route.meta.section === 'ENTRIES1C' ? 'router-link-exact-active active' : ''" v-if="userInOneOfRoles('ROLE_PARTNER', 'ROLE_ADMIN')">
					<svg width="24" height="24" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M4.80695 0C4.01691 0 3.29944 0.456726 2.97108 1.1687L0.182623 7.21466C-0.0623109 7.74567 -0.060785 8.3562 0.186713 8.88605L2.96827 14.8401C3.29865 15.5474 4.01356 16 4.79999 16H19.9815C21.0963 16 22 15.1046 22 14V2C22 0.895447 21.0963 0 19.9815 0H4.80695ZM13.3723 10.3152C13.3886 10.3148 13.4048 10.3141 13.421 10.3134V10.3157H18.6842V11.9999H13.3261L13.3158 12L13.3055 11.9999H13.1053V11.9945C10.994 11.8851 9.3158 10.1385 9.3158 8C9.3158 5.79083 11.1066 4 13.3158 4C15.236 4 16.8403 5.35315 17.227 7.1579H15.4737C15.1368 6.29529 14.2977 5.6842 13.3158 5.6842C12.0368 5.6842 11 6.72101 11 8C11 9.27631 12.0325 10.3115 13.3079 10.3157H13.3237C13.34 10.3157 13.3561 10.3156 13.3723 10.3152ZM8.4737 4.21057H6.7895V5.47375H5.00001V7.15796H6.7895V12H8.4737V4.21057Z" fill="#AEC9E5"/>
					</svg>
					Проводки из 1С
				</router-link>

		</nav>

    <v-menu style="max-width: 300px" :nudge-bottom="38" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <div class="profile" v-on="on"><span>Профиль</span></div>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item @click="$keycloak.logout()">
            <v-list-item-icon>
              <v-icon color="rgba(0,0,0,.54)">mdi-exit-to-app</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
	</header>


    <main>
    <router-view :set-section="setSection" />
    </main>

  </v-app>
</template>

<style lang="scss">
@import "./styles/main.scss";
</style>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>



<script>
import {userInOneOfRoles} from "@/modules/CommonUtils";

export default {
  props: {
  },
  data() {
    return {
      manualSection: null
    };
  },
  methods: {
    userInOneOfRoles,

    setSection(sectionName){
      this.manualSection = sectionName
    },

    isActiveSection(sectionName){
      if(sectionName === 'TENDERS' || sectionName === 'APPLICATIONS'){
        if(this.$route.meta.section !== 'TENDERS' && this.$route.meta.section !== 'APPLICATIONS'){
          return false
        }
        return this.manualSection === sectionName
      }
      if(sectionName === 'KNOWLEDGE'){
        return this.$route.fullPath.startsWith('/knowledge')
      }
      return this.$route.meta.section === sectionName
    }
  }
};
</script>
